<template>
  <div>
    <div class="card p-5 mx-auto">
      <h2 class="text-center">Archivierte Bauvorhaben</h2>
      <p class="text-center">Bearbeiten und löschen</p>
      <div class="mt-3">
        <mdb-tbl>
          <mdb-tbl-head>
            <tr>
              <th>Name</th>
              <th>Zusatz</th>
              <th>Produktiv</th>
              <th>Unproduktiv</th>
              <th>Aktiv</th>
              <th></th>
              <th></th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr
              class="construction-project-table"
              v-for="(project, index) in projects"
              :key="index"
            >
              <template v-if="project.active === 0">
                <td>{{ project.name }}</td>
                <td>{{ project.feat }}</td>
                <td>
                  <mdb-icon v-if="project.productive === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.productive === 0" icon="times" class="red-text" />
                </td>
                <td>
                  <mdb-icon v-if="project.unproductive === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.unproductive === 0" icon="times" class="red-text" />
                </td>
                <td>
                  <mdb-icon v-if="project.active === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.active === 0" icon="times" class="red-text" />
                </td>
                <td @click="setToActive(project)">
                  <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
                    <span slot="tip">{{ project.name }} aktiv stellen</span>
                    <a slot="reference"><mdb-icon far icon="calendar-times" /></a>
                  </mdb-tooltip>
                </td>
                <td @click="deleteProject(project)">
                  <mdb-icon class="admin-delete-icon" far icon="trash-alt" />
                </td>
              </template>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbTbl, mdbTblHead, mdbTblBody, mdbIcon, mdbTooltip } from 'mdbvue';
import { mapGetters } from 'vuex';

export default {
  components: {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbTooltip,
  },
  data() {
    return {};
  },

  mounted() {
    if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
      this.$store.dispatch('REFRESH_TOKEN', localStorage.getItem('JWT')).then(() => {
        this.$store.dispatch('GET_PROJECTS');
      });
    }
  },
  computed: {
    ...mapGetters(['projects']),
  },
  methods: {
    deleteProject(project) {
      this.$store.dispatch('DELETE_PROJECT', project.id).then(() => {
        this.$store.dispatch('GET_PROJECTS');
      });
    },

    setToActive(clickedProject) {
      const data = {
        id: clickedProject.id,
        active: 1,
      };

      this.$store.dispatch('UPDATE_STATE_PROJECT', data).then(() => {
        this.$store.dispatch('GET_PROJECTS');
      });
    },
  },
};
</script>
