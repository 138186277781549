var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5"},[_c('h2',{staticClass:"text-center"},[_vm._v("Mitarbeiter Stunden auslesen")]),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text-center"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedUser),expression:"selectedUser"}],staticClass:"browser-default custom-select w-25",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedUser=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"1","disabled":""}},[_vm._v("Mitarbeiter auswählen")]),_vm._l((_vm.users),function(user,key){return _c('option',{key:key},[_vm._v(_vm._s(user))])})],2)]),_c('mdb-btn',{staticClass:"w-25 mx-auto mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.setWeekData()}}},[_vm._v(" Stunden anzeigen ")])],1),_c('div',{staticClass:"stundentool mt-5"},[_c('h2',{staticClass:"text-center"},[(_vm.currentWeekSaver === _vm.weekNumber)?_c('span',[_vm._v("Wir befinden uns in ")]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v("KW"+_vm._s(_vm.weekNumber)+" "+_vm._s(_vm.currentYear))]),(_vm.selectedUser !== 1)?_c('span',[_vm._v(" (Stunden von "+_vm._s(_vm.selectedUser)+")")]):_vm._e()]),_c('div',{staticClass:"row"},[(!_vm.calenderLoading && _vm.displayedData)?_c('div',{staticClass:"hours-calender mx-auto p-5 d-flex flex-wrap justify-content-center"},[_c('i',{staticClass:"fas fa-arrow-circle-left fa-2x lastWeek",on:{"click":function($event){return _vm.resetWeekTo('last')}}}),_vm._l((_vm.weekData),function(element,key){return _c('div',{key:key,staticClass:"hours-calender__day d-flex flex-column text-center mx-2"},[_c('div',{class:{
              'font-weight-bold': element.current,
              'text-info': element.current,
              'error-text': element.error !== '',
            }},[_vm._v(" "+_vm._s(element.date)+" ")]),_c('div',{staticClass:"hours-calender__day-area mx-auto d-flex flex-column",class:{
              'hours-calender__day-area--filled': element.data.length > 0,
              'justify-content-between': element.data.length > 0,
              'align-items-center': element.data.length === 0,
              'justify-content-center': element.data.length === 0,
            }},[(element.data.length > 0)?_c('div',_vm._l((element.data),function(dayData,key){return _c('mdb-badge',{key:key,staticClass:"data-badge d-flex flex-column",attrs:{"color":_vm.getBadgeColor(dayData.project)}},[_c('span',{staticClass:"badge-text"},[_vm._v(_vm._s(dayData.project)+" "),_c('br'),_vm._v(" "+_vm._s(dayData.hours)+" Stunden")])])}),1):_vm._e()])])}),_c('i',{staticClass:"fas fa-arrow-circle-right fa-2x nextWeek",on:{"click":function($event){return _vm.resetWeekTo('next')}}})],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }