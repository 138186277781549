<template>
  <div class="p-5">
    <h2 class="text-center">Mitarbeiter Stunden auslesen</h2>
    <div class="d-flex flex-column justify-content-center">
      <div class="text-center">
        <select class="browser-default custom-select w-25" v-model="selectedUser">
          <option value="1" disabled>Mitarbeiter auswählen</option>
          <option v-for="(user, key) in users" :key="key">{{ user }}</option>
        </select>
      </div>
      <mdb-btn class="w-25 mx-auto mt-2" color="primary" @click="setWeekData()">
        Stunden anzeigen
      </mdb-btn>
    </div>
    <div class="stundentool mt-5">
      <h2 class="text-center">
        <span v-if="currentWeekSaver === weekNumber">Wir befinden uns in </span>
        <span class="font-weight-bold">KW{{ weekNumber }} {{ currentYear }}</span>
        <span v-if="selectedUser !== 1"> (Stunden von {{ selectedUser }})</span>
      </h2>
      <div class="row">
        <div
          v-if="!calenderLoading && displayedData"
          class="hours-calender mx-auto p-5 d-flex flex-wrap justify-content-center"
        >
          <i class="fas fa-arrow-circle-left fa-2x lastWeek" @click="resetWeekTo('last')"></i>
          <div
            class="hours-calender__day d-flex flex-column text-center mx-2"
            v-for="(element, key) in weekData"
            :key="key"
          >
            <div
              :class="{
                'font-weight-bold': element.current,
                'text-info': element.current,
                'error-text': element.error !== '',
              }"
            >
              {{ element.date }}
            </div>
            <div
              class="hours-calender__day-area mx-auto d-flex flex-column"
              :class="{
                'hours-calender__day-area--filled': element.data.length > 0,
                'justify-content-between': element.data.length > 0,
                'align-items-center': element.data.length === 0,
                'justify-content-center': element.data.length === 0,
              }"
            >
              <div v-if="element.data.length > 0">
                <mdb-badge
                  class="data-badge d-flex flex-column"
                  v-for="(dayData, key) in element.data"
                  :key="key"
                  :color="getBadgeColor(dayData.project)"
                >
                  <span class="badge-text"
                    >{{ dayData.project }} <br />
                    {{ dayData.hours }} Stunden</span
                  >
                </mdb-badge>
              </div>
            </div>
          </div>
          <i class="fas fa-arrow-circle-right fa-2x nextWeek" @click="resetWeekTo('next')"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdbBtn, mdbBadge } from 'mdbvue';

export default {
  components: {
    mdbBtn,
    mdbBadge,
  },
  data() {
    return {
      users: ['Torsten', 'Kira', 'Andrea'],
      selectedUser: 1,
      weekData: [],
      currentYear: null,
      weekNumber: null,
      calenderLoading: false,
      currentWeekSaver: null,
    };
  },
  computed: {
    ...mapGetters(['displayedData']),
  },
  mounted() {
    this.currentYear = new Date().getFullYear();

    const weekDay = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];

    for (let i = 1; i < weekDay.length; i++) {
      const obj = {
        day: weekDay[i],
        date: '',
        current: false,
        submitFormOpen: false,
        data: [],
        timeId: null,
        startTime: '',
        endTime: '',
        total: '0',
        error: '',
      };
      this.weekData.push(obj);
    }
    var current = new Date(),
      dayNumber = current.getDay();

    const currentDay = weekDay[dayNumber];
    if (currentDay !== 'Sonntag') {
      this.weekData.find((day) => day.day === currentDay).current = true;
    }
    const result = this.getWeekNumber(new Date());
    this.weekNumber = result[1];

    // ! A saver for day/week
    this.currentWeekSaver = result[1];
    this.currentDaySaver = currentDay;

    /**
     * Initial loading
     */
    this.changeWeekDates();
  },
  methods: {
    setWeekData() {
      this.calenderLoading = true;
      this.$store
        .dispatch('GET_WEEK_DATA', {
          week: this.weekNumber,
          year: this.currentYear,
          user: this.selectedUser,
        })
        .then(() => {
          this.weekData.forEach((object) => {
            object.data = [];
          });
          console.log(this.displayedData);
          this.displayedData.forEach((object) => {
            this.weekData.find((day) => day.day === object.day).data.push(object);
          });
          this.calenderLoading = false;
        });
    },

    resetWeekTo(selection) {
      if (selection === 'last') {
        this.weekNumber = parseInt(this.weekNumber) - 1;
        this.weekData.forEach((object) => {
          object.startTime = '';
          object.endTime = '';
          object.timeId = null;
          object.current = false;
        });
        if (this.weekNumber === this.currentWeekSaver) {
          if (this.currentDaySaver !== 'Sonntag') {
            this.weekData.find((day) => day.day === this.currentDaySaver).current = true;
          }
        }
        this.setWeekData();
        this.changeWeekDates();
        if (this.weekNumber === 0) {
          this.weekNumber = 52;
        }
      } else if (selection === 'next') {
        if (this.weekNumber === 52 || this.weekNumber === 53) {
          // need to check for weeks in year (only 52 weeks possible)
          const lastDayInDecember = new Date(this.currentYear, 11, 31);
          if (this.getWeekNumber(lastDayInDecember)[1] === 53) {
            this.weekNumber = 53;
          } else {
            this.weekNumber = 1;
            this.currentYear = this.currentYear + 1;
          }
        } else {
          this.weekNumber = parseInt(this.weekNumber) + 1;
        }
        this.weekData.forEach((object) => {
          object.startTime = '';
          object.endTime = '';
          object.timeId = null;
          object.current = false;
        });
        if (this.weekNumber === this.currentWeekSaver) {
          if (this.currentDaySaver !== 'Sonntag') {
            this.weekData.find((day) => day.day === this.currentDaySaver).current = true;
          }
        }
        this.setWeekData();
        this.changeWeekDates();
      }
    },

    getBadgeColor(project) {
      if (
        project === 'Krank' ||
        project === 'Urlaub' ||
        project === 'unbezahlter Urlaub' ||
        project === 'Zeitkonto'
      ) {
        switch (project) {
          case 'Krank':
            return 'secondary';
          case 'Urlaub':
            return 'unique-color';
          case 'unbezahlter Urlaub':
            return 'unique-color';
          case 'Zeitkonto':
            return 'stylish-color';
        }
      } else {
        return 'info';
      }
    },
    changeWeekDates() {
      const deWeek = ['Mo:', 'Di:', 'Mi:', 'Do:', 'Fr:', 'Sa:', 'So:'];
      const weekArray = this.getWeek(this.getDateOfISOWeek(this.weekNumber, this.currentYear));
      const yearOfDate = weekArray[0].getFullYear();
      this.currentYear = yearOfDate;
      for (let i = 1; i <= 6; i++) {
        this.weekData[i - 1].date =
          deWeek[i - 1] + ' ' + new Date(weekArray[i]).toLocaleDateString('de-DE');
      }
    },
    getWeek(fromDate) {
      const sunday = new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay())),
        result = [new Date(sunday)];
      while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
        result.push(new Date(sunday));
      }
      return result;
    },
    getDateOfISOWeek(w, y) {
      const simple = new Date(y, 0, 1 + (w - 1) * 7);
      const dow = simple.getDay();
      const ISOweekStart = simple;
      if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    },
    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return [d.getUTCFullYear(), weekNo];
    },
  },
};
</script>
