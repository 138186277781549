<template>
  <div class="admin">
    <div class="admin__container p-3">
      <mdb-tabs
        class="admin__tabs"
        @activeTab="getActiveTabIndex"
        :active="0"
        default
        :links="tabs"
      >
        <template :slot="[activeLink]">
          <component :is="TabComponent"></component>
        </template>
      </mdb-tabs>
    </div>
  </div>
</template>

<script>
import {
  mdbInput,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbIcon,
  mdbContainer,
  mdbTabs,
} from 'mdbvue';
import Kalkulation from '../components/admin/Kalkulation';
import Abgeschlossene_Bauvorhaben from '../components/admin/Abgeschlossene_Bauvorhaben';
import Bauvorhaben from '../components/admin/Bauvorhaben';
import Gespeicherte_Kalkulationen from '../components/admin/Gespeicherte_Kalkulationen';
import Stundenzettel from '../components/admin/Stundenzettel';
import Mitarbeiter from '../components/admin/Mitarbeiter';

export default {
  components: {
    mdbInput,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbContainer,
    mdbTabs,
    Kalkulation,
    Bauvorhaben,
    Abgeschlossene_Bauvorhaben,
    Gespeicherte_Kalkulationen,
    Stundenzettel,
    Mitarbeiter,
  },
  data() {
    return {
      active: 0,
      tabs: [
        {
          value: 'Bauvorhaben',
          text: 'Bauvorhaben',
        },
        {
          value: 'Abgeschlossene_Bauvorhaben',
          text: 'Abgeschlossene_Bauvorhaben',
        },
        {
          value: 'Kalkulation',
          text: 'Kalkulation',
        },
        {
          value: 'Gespeicherte_Kalkulationen',
          text: 'Gespeicherte_Kalkulationen',
        },
        {
          value: 'Stundenzettel',
          text: 'Stundenzettel',
        },
        {
          value: 'Mitarbeiter',
          text: 'Mitarbeiter',
        },
      ],
    };
  },
  computed: {
    activeLink() {
      return this.tabs[this.active].text;
    },
    TabComponent: function () {
      return this.tabs[this.active].text;
    },
  },
  methods: {
    getActiveTabIndex(index) {
      this.active = index;
    },
  },
};
</script>
