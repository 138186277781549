<template>
  <div>
    <div class="card p-5 mx-auto">
      <h2 class="text-center">Kalkulationen</h2>
      <div class="mt-3 d-flex justify-content-center flex-column">
        <select class="w-25 browser-default custom-select mt-3 mx-auto" v-model="selectedProject">
          <option value="1" disabled selected>Baustelle auswählen</option>
          <option v-for="(project, key) in projectsForCalculation" :key="key">
            {{ project }}
          </option>
        </select>
        <mdb-btn class="w-25 mt-5 mx-auto" @click="addProject()">
          <mdb-icon class="cursor-pointer mx-auto" icon="plus" size="lg" />
        </mdb-btn>
        <ul class="mt-3 mx-auto">
          <li v-for="(project, key) in selectedProjectsForCalc" :key="key">
            <div>
              {{ project }}
              <mdb-btn color="danger" @click="removeProjectFromList(key)">
                <mdb-icon class="cursor-pointer" icon="trash" />
              </mdb-btn>
            </div>
          </li>
        </ul>
        <mdb-btn class="w-25 mx-auto mt-5" color="deep-purple" @click="startCalculation()">
          Stunden Auslesen
        </mdb-btn>
        <div class="mt-5" v-if="calculationStarted">
          <mdb-tbl>
            <mdb-tbl-head>
              <tr>
                <th>Mitarbeiter</th>
                <th>Unproduktiv</th>
                <th>Produktiv</th>
                <th>Gesamt</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="(worker, index) in calculatedTable" :key="index">
                <td>{{ worker.worker }}</td>
                <td>{{ worker.unproductive }}</td>
                <td>{{ worker.productive }}</td>
                <td>{{ worker.all }}</td>
              </tr>
              <tr>
                <td>Gesamt</td>
                <td>{{ allUnproductiveHour }}</td>
                <td>{{ allProductiveHour }}</td>
                <td>{{ allHoursFromAll }}</td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <label class="mt-3 font-weight-bold">Umsatz</label>
            <input type="text" class="form-control w-50" v-model="sales" placeholder="in €" />
            <label class="mt-3 font-weight-bold">Materialkosten</label>
            <input
              type="text"
              class="form-control w-50"
              v-model="materialCosts"
              placeholder="in €"
            />
            <label class="mt-3 font-weight-bold">Lohnkosten</label>
            <input type="text" class="form-control w-50" v-model="wageCosts" placeholder="in €" />
            <mdb-btn class="w-50 mx-auto mt-3" color="deep-purple" @click="mathCalculation()">
              Kalkulation Starten
            </mdb-btn>
            <div class="mt-5">
              Produktiver erarbeiteter Stundenlohn: {{ sumProductive }}€
              <hr />
              Gesamter erarbeiteter Stundenlohn: {{ sumTotal }}€
            </div>
            <div class="w-100 text-center">
              <label class="mt-3 font-weight-bold mx-auto">Name</label>
              <input
                type="text"
                class="form-control w-50 mx-auto"
                v-model="nameOfMultipleCalc"
                placeholder="Bennenung der Kalkulation"
              />
            </div>
            <mdb-btn class="w-25 mx-auto mt-5" color="primary" @click="saveCalculation()">
              Kalkulation Speichern
            </mdb-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mdbBtn, mdbTbl, mdbTblHead, mdbTblBody, mdbIcon } from 'mdbvue';
import { mapGetters } from 'vuex';

export default {
  components: {
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
  },
  data() {
    return {
      productive: null,
      unproductive: null,
      workers: [
        { name: 'Matthias' },
        { name: 'Wolfgang' },
        { name: 'Torsten' },
        { name: 'Mohammed' },
      ],
      constructionProjectNames: [],
      calculationStarted: false,
      selectedProject: 1,
      calculatedTable: [],
      allHoursFromAll: 0,
      allUnproductiveHour: 0,
      allProductiveHour: 0,
      selectedProjectsForCalc: [],

      sales: null,
      materialCosts: null,
      sumProductive: null,
      sumTotal: null,

      wageCosts: null,
      calculationList: [],
      projectsForCalculation: [],

      nameOfMultipleCalc: null,
    };
  },
  computed: {
    ...mapGetters(['projects', 'noFeatProjects']),
  },
  mounted() {
    axios
      .post(
        this.$store.state.apiDomain + '/calculation/get-all',
        {},
        { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } },
      )
      .then((response) => {
        this.calculationList = response.data;
        this.noFeatProjects.forEach((project) => {
          if (
            this.calculationList.find((element) => element.project === project.name) === undefined
          ) {
            this.projectsForCalculation.push(project.name);
          }
        });
      });
  },
  methods: {
    // ! Calculation area
    startCalculation() {
      // ? Initial empty inputs
      this.sales = null;
      this.materialCosts = null;
      this.sumProductive = null;
      this.sumTotal = null;

      this.calculatedTable = [];

      if (this.selectedProjectsForCalc.length === 1) {
        this.workers.forEach((worker) => {
          let workerObj = {
            worker: worker.name,
            unproductive: 0,
            productive: 0,
            all: 0,
          };
          const data = {
            worker: worker.name,
            project: this.selectedProject,
          };
          this.$store.dispatch('GET_HOURS', data).then((response) => {
            workerObj = {
              worker: worker.name,
              unproductive: this.unproductiveHoursCalculation(response.data.data),
              productive: this.productiveHoursCalculation(response.data.data),
              all: this.allHoursCalculation(response.data.data),
            };
            if (worker.name !== 'Marek' && worker.name !== 'WolfgangZettel') {
              this.calculatedTable.push(workerObj);
              this.calculateAll();
            }
          });
        });
      } else {
        // multiple projects
        this.selectedProjectsForCalc.forEach((project) => {
          let workerObj = {
            worker: null,
            unproductive: 0,
            productive: 0,
            all: 0,
          };

          this.workers.forEach((worker) => {
            const data = {
              worker: worker.name,
              project: project,
            };
            this.$store.dispatch('GET_HOURS', data).then((response) => {
              workerObj = {
                worker: worker.name,
                unproductive: this.unproductiveHoursCalculation(response.data.data),
                productive: this.productiveHoursCalculation(response.data.data),
                all: this.allHoursCalculation(response.data.data),
              };
              if (worker.name !== 'Marek' && worker.name !== 'WolfgangZettel') {
                this.calculatedTable.push(workerObj);
                this.calculateAll();
              }
            });
          });
        });
      }

      this.calculationStarted = true;
    },

    productiveHoursCalculation(data) {
      let amount = 0;
      data.forEach((singleData) => {
        if (singleData.productive === 1) {
          amount = amount + parseInt(singleData.hours);
        }
      });
      return amount;
    },
    unproductiveHoursCalculation(data) {
      let amount = 0;
      data.forEach((singleData) => {
        if (singleData.unproductive === 1) {
          amount = amount + parseInt(singleData.hours);
        }
      });
      return amount;
    },
    allHoursCalculation(data) {
      let amount = 0;
      data.forEach((singleData) => {
        amount = amount + parseInt(singleData.hours);
      });
      return amount;
    },

    calculateAll() {
      this.allHoursFromAll = 0;
      this.allUnproductiveHour = 0;
      this.allProductiveHour = 0;
      this.calculatedTable.forEach((worker) => {
        this.allHoursFromAll = this.allHoursFromAll + worker.all;
        if (worker.unproductive !== 0) {
          this.allUnproductiveHour = this.allUnproductiveHour + worker.unproductive;
        }
        if (worker.productive !== 0) {
          this.allProductiveHour = this.allProductiveHour + worker.productive;
        }
      });
    },

    mathCalculation() {
      this.sumProductive = 0;
      this.sumTotal = 0;

      const sum = parseInt(this.sales) - parseInt(this.materialCosts);

      this.sumProductive = (sum / parseInt(this.allProductiveHour)).toFixed(2);
      this.sumTotal = (sum / parseInt(this.allHoursFromAll)).toFixed(2);
    },

    saveCalculation() {
      const data = {
        project: this.nameOfMultipleCalc ? this.nameOfMultipleCalc : this.selectedProject,
        productiveEarn: this.sumProductive,
        fullEarn: this.sumTotal,
        revenue: this.sales,
        materialcosts: this.materialCosts,
        wagecosts: this.wageCosts,
      };

      this.$store.dispatch('ADD_CALCULATION', data).then(() => {
        this.sales = null;
        this.materialCosts = null;
        this.sumProductive = null;
        this.sumTotal = null;
        this.wageCosts = null;
        this.nameOfMultipleCalc = null;
      });
    },
    addProject() {
      if (this.selectedProject !== 1 && this.selectedProject !== null) {
        this.selectedProjectsForCalc.push(this.selectedProject);
        this.selectedProject = 1;
      }
    },
    removeProjectFromList(key) {
      this.selectedProjectsForCalc.splice(key, 1);
    },
  },
};
</script>
