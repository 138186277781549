<template>
  <div>
    <div class="card p-5 mx-auto">
      <h2 class="text-center">Kalkulationen</h2>
      <ul class="mx-auto w-50">
        <li v-for="(calculation, key) in calculationList" :key="key">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <span class="mr-3">{{ calculation.project }}</span>
            <div>
              <button
                class="btn btn-success mr-3 p-2"
                color="success"
                @click="(modal = true), openCalculation(calculation.id)"
              >
                Öffnen
              </button>
              <button
                class="btn btn-danger p-2"
                color="danger"
                @click="(deleteModal = true), (calculationForDelete = calculation)"
              >
                Löschen
              </button>
            </div>
          </div>
        </li>
      </ul>
      <mdb-modal :show="modal" @close="modal = false && closeCalculation()">
        <mdb-modal-header>
          <mdb-modal-title>Ausgewählte Kalkulation</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div v-if="focusCalculation !== null">
            Bauvorhaben: {{ focusCalculation.project }}<br />
            Umsatz: {{ focusCalculation.revenue }}€ <br />
            Lohnkosten: {{ focusCalculation.wagecosts }}€<br />
            Materialkosten: {{ focusCalculation.materialcosts }}€<br />
            Gesamter erarbeiteter Stundenlohn: {{ focusCalculation.full_earn }}€<br />
            Produktiver erarbeiteter Stundenlohn:
            {{ focusCalculation.productive_earn }}€<br />
          </div>
        </mdb-modal-body>
      </mdb-modal>
    </div>
    <submit-delete-modal
      :showModal="deleteModal"
      :deletedItemString="calculationForDelete.project"
      v-on:deleteDenied="deleteModal = false"
      v-on:deleteAccept="(deleteModal = false), deleteCalculation(calculationForDelete.id)"
    ></submit-delete-modal>
  </div>
</template>

<script>
import SubmitDelete from '../SubmitDelete.vue';
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody } from 'mdbvue';
import axios from 'axios';

export default {
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalTitle,
    'submit-delete-modal': SubmitDelete,
  },
  data() {
    return {
      calculationList: [],
      focusCalculation: null,

      calculationForDelete: {
        id: null,
        project: null,
      },

      modal: false,
      deleteModal: false,
    };
  },

  mounted() {
    this.getCalculation();
  },

  methods: {
    getCalculation() {
      this.calculationList = [];

      axios
        .post(
          this.$store.state.apiDomain + '/calculation/get-all',
          {},
          { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } },
        )
        .then((response) => {
          this.calculationList = response.data;
        });
    },

    openCalculation(id) {
      this.focusCalculation = this.calculationList.find((calculation) => calculation.id === id);
    },

    deleteCalculation(id) {
      axios
        .delete(this.$store.state.apiDomain + '/calculation/delete', {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          data: {
            id: id,
          },
        })
        .then(() => {
          this.getCalculation();
          this.calculationForDelete = {
            id: null,
            name: null,
          };
        });
    },

    closeCalculation() {
      this.focusCalculation = null;
    },
  },
};
</script>

<style></style>
