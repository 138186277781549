<template>
  <div>
    <div class="card p-5 mx-auto">
      <h2 class="text-center">Aktive Bauvorhaben</h2>
      <p class="text-center">Hinzufügen, bearbeiten und löschen</p>
      <div class="w-25 mx-auto mt-3 d-flex justify-content-center flex-column">
        <mdb-input
          class="mt-0"
          v-model="constructionProjectName"
          label="Name"
          icon="house"
          type="text"
        />
        <mdb-input
          class="mt-0"
          v-model="constructionProjectFeat"
          label="Zusätzlich ()"
          icon="house"
          type="text"
        />
        <mdb-btn class="mx-auto" color="deep-purple" @click="addProjects">
          <span class="font-weight-bold" v-if="constructionProjectName !== ''">
            {{ constructionProjectName
            }}<span v-if="constructionProjectFeat !== ''">, ({{ constructionProjectFeat }})</span>
          </span>
          Hinzufügen
        </mdb-btn>
      </div>
      <div class="mt-3">
        <mdb-tbl>
          <mdb-tbl-head>
            <tr>
              <th>Name</th>
              <th>Zusatz</th>
              <th>Produktiv</th>
              <th>Unproduktiv</th>
              <th>Aktiv</th>
              <th></th>
              <th></th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr
              class="construction-project-table"
              v-for="(project, index) in projects"
              :key="index"
            >
              <template v-if="project.active === 1">
                <td>{{ project.name }}</td>
                <td>{{ project.feat }}</td>
                <td>
                  <mdb-icon v-if="project.productive === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.productive === 0" icon="times" class="red-text" />
                </td>
                <td>
                  <mdb-icon v-if="project.unproductive === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.unproductive === 0" icon="times" class="red-text" />
                </td>
                <td>
                  <mdb-icon v-if="project.active === 1" icon="check" class="green-text" />
                  <mdb-icon v-if="project.active === 0" icon="times" class="red-text" />
                </td>
                <td @click="setToInactive(project)">
                  <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
                    <span slot="tip">{{ project.name }} abgeschlossen</span>
                    <a slot="reference"><mdb-icon far icon="calendar-times" /></a>
                  </mdb-tooltip>
                </td>
                <td @click="(deleteModal = true), (projectForDeletion = project)">
                  <mdb-icon class="admin-delete-icon" far icon="trash-alt" />
                </td>
              </template>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </div>
    <submit-delete-modal
      :showModal="deleteModal"
      :deletedItemString="projectForDeletion.name"
      v-on:deleteDenied="deleteModal = false"
      v-on:deleteAccept="(deleteModal = false), deleteProjects(projectForDeletion)"
    ></submit-delete-modal>
  </div>
</template>

<script>
import SubmitDelete from '../SubmitDelete.vue';
import { mdbInput, mdbBtn, mdbTbl, mdbTblHead, mdbTblBody, mdbIcon, mdbTooltip } from 'mdbvue';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: {
    mdbInput,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbTooltip,
    'submit-delete-modal': SubmitDelete,
  },
  data() {
    return {
      constructionProjectName: '',
      constructionProjectFeat: '',
      deleteModal: false,
      projectForDeletion: {
        name: '',
      },
    };
  },

  mounted() {
    if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
      this.$store.dispatch('REFRESH_TOKEN', localStorage.getItem('JWT')).then(() => {
        this.$store.dispatch('GET_PROJECTS');
      });
    }
  },
  computed: {
    ...mapGetters(['projects']),
  },
  methods: {
    axiosGetConstructionProjects() {
      axios.get(this.$store.state.apiDomain + '/get-projects', {}).then((response) => {
        this.constructionProjects = [];
        const data = response.data.data;
        data.forEach((object) => {
          this.constructionProjects.push(object);
        });
      });
    },

    addProjects() {
      if (this.constructionProjectFeat !== '') {
        this.productive = 0;
        this.unproductive = 1;
      } else {
        this.productive = 1;
        this.unproductive = 0;
      }

      const data = {
        name: this.constructionProjectName,
        productive: this.productive,
        unproductive: this.unproductive,
        feat: this.constructionProjectFeat,
        active: 1,
      };

      this.$store.dispatch('ADD_PROJECT', data).then(() => {
        this.constructionProjectName = '';
        this.constructionProjectFeat = '';
        this.$store.dispatch('GET_PROJECTS');
      });
    },

    deleteProjects(project) {
      this.$store.dispatch('DELETE_PROJECT', project.id).then(() => {
        this.$store.dispatch('GET_PROJECTS');
        this.projectForDeletion = {
          name: '',
        };
      });
    },

    setToInactive(clickedProject) {
      const data = {
        id: clickedProject.id,
        active: 0,
      };

      this.$store.dispatch('UPDATE_STATE_PROJECT', data).then(() => {
        this.$store.dispatch('GET_PROJECTS');
      });
    },
  },
};
</script>
