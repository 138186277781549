<template>
  <mdb-modal centered :show="showModal" @close="deleteDenied()">
    <mdb-modal-header>
      <mdb-modal-title>Wirklich {{ deletedItemString }} <strong>löschen</strong>?</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="d-flex flex-row">
        <button @click="deleteAccept()" class="w-50 btn btn-danger p-2">Ja</button>
        <button @click="deleteDenied()" class="w-50 btn btn-success p-2">Nein</button>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody } from 'mdbvue';

export default {
  name: 'SubmitDelete',
  props: ['showModal', 'deletedItemString'],
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalTitle,
  },
  data() {
    return {};
  },
  methods: {
    deleteAccept() {
      this.$emit('deleteAccept');
    },
    deleteDenied() {
      this.$emit('deleteDenied');
    },
  },
};
</script>
